import React from 'react'
import SEO from '../components/SEO'
import NavbarIndex from '../components/NavbarIndex'
import WelcomeVideoHome from '../components/IndexComps/WelcomeVideoHome'
import Howandwhy from '../components/IndexComps/Howandwhy'
import Usercount from '../components/IndexComps/Usercount'
import Featuresindex from '../components/IndexComps/Featuresindex'
import Peerreviews from '../components/IndexComps/Peerreviews'
import Signupnow2 from '../components/CTAsections/Signupnow2'
import Signupnowbar from '../components/CTAsections/Signupnowbar'
import Footer from '../components/Footer'
import OffCanvas from '../components/OffCanvas'
import SignupFormMob from '../components/SignupFormMob'

import {StaticQuery, graphql} from 'gatsby'

const SCROLL_MIN = 60

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasScrolled: false, open: false}
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = event => {
    if (!this.state.hasScrolled && event.target.scrollingElement.scrollTop >= SCROLL_MIN) {
      this.setState({
        hasScrolled: false,
      })
    }
    if (this.state.hasScrolled && event.target.scrollingElement.scrollTop < SCROLL_MIN) {
      this.setState({
        hasScrolled: false,
      })
    }
  }

  onClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }
  toggleModal = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                appUrl
              }
            }
          }
        `}
        render={data => {
          const appUrl = data.site.siteMetadata.appUrl
          return (
            <div onScroll={this.onScroll}>
              <SEO
                title="(HLM) HomeLight Listing Management  | On-market to offer accepted, faster! - Sign Up Now (It's free!)"
                meta={[
                  {name: `title`, content: "HomeLight Listing Management (HLM) | On-market to offer accepted, faster! - Sign Up Now! (It's free!)"},
                  {
                    name: `description`,
                    content:
                      'One software for cooperating agents. Securely share property information, monitor buyer interest and compare offers in one place. Simple, effective, and trusted by Top Real Estate Agents everywhere. ',
                  },
                  {
                    property: `og:image`,
                    content: 'https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/HLM+Social+Final+1.png',
                  },
                  {property: 'og:image:width', content: '600'},
                  {property: 'og:image:height', content: '315'},
                  {property: `og:title`, content: 'HomeLight Listing Management (HLM)'},
                  {property: 'og:url', content: 'https://disclosures.io'},
                  {
                    property: `og:description`,
                    content:
                      'One software for cooperating agents. Securely share property information, monitor buyer interest and compare offers in one place. Simple, effective, and trusted by Top Real Estate Agents everywhere.',
                  },
                  {property: 'og:site_name', content: 'HomeLight Listing Management (HLM)'},
                  {property: `og:type`, content: `website`},
                  {name: `twitter:card`, content: `summary_large_image`},
                  {name: `twitter:site`, content: `@Disclosuresio`},
                  {name: `twitter:creator`, content: '@Disclosuresio'},
                  {name: `twitter:title`, content: "HomeLight Listing Management (HLM) | On-market to offer accepted, faster! - Sign Up Now! (It's free!)"},
                  {
                    name: `twitter:description`,
                    content:
                      'One software for cooperating agents. Securely share property information, monitor buyer interest and compare offers in one place. Simple, effective, and trusted by Top Real Estate Agents everywhere. ',
                  },
                  {
                    name: 'keywords',
                    content:
                      'HomeLight Listing Management (HLM), Disclosures.io, Disclosures.io pricing, Disclosures.io reviews, Disclosures.io How it works, Disclosures.io BBB, real estate broker tools, real estate listing management, disclosure package, property package, real estate disclosure, realtor crm, nhd report, property disclosure, real estate transaction management, realtor tools, tc tools, real estate crm, real estate software, buyers agent, home disclosure, listing agent,',
                  },
                ]}
              />

              {/* ANNOUNCEMENT */}
              <div className="announcement-container-top" style={{}}>
                <div class="container grid-xl ">
                  <div class=" bg-transparent announcement-bar">
                    <p className=" text-center " style={{fontSize: '.9em'}}>
                      *Disclosures.io is now "HomeLight Listing Management" (HLM).{' '}
                      <a href="https://academy.disclosures.io/en/hlm " target="_blank" style={{color: '#273753', textDecoration: 'underline'}}>
                        Read more about our growth and success story.
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              {/* ANNOUNCEMENT */}

              {/* NAV BAR MOBILE */}
              <OffCanvas open={this.state.open} onDismiss={this.onClick}>
                <div key="sidebar" className="bg-white" style={{padding: '0rem'}}>
                  <div className="pt-2 ">
                    <a href="/features" style={{color: '#46b6ff'}}>
                      <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                        &nbsp;FEATURES
                      </div>
                    </a>
                  </div>
                  <div className="pt-1 ">
                    <a href="/agents" style={{color: '#46b6ff'}}>
                      <div className="btn  btn-md col-12 text-left  " style={{border: '0px solid blue', color: '#46b6ff'}}>
                        &nbsp;Services <i className="fas fa-hands-helping fa-lg " style={{color: '#46b6ff'}} />
                        &nbsp;
                      </div>
                    </a>
                  </div>

                  <div className="pt-1 ">
                    <a href="/pricing" style={{color: '#46b6ff'}}>
                      <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                        &nbsp;PRICING
                      </div>
                    </a>
                  </div>
                  <div className="pt-1 ">
                    <a
                      href="https://itunes.apple.com/us/app/disclosures-io/id1435244080?mt=8"
                      target="_blank"
                      rel="noopener noreferrer"
                      universal="true"
                      style={{color: '#46b6ff'}}>
                      <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                        &nbsp;&nbsp;
                        <i className="fab fa-apple" style={{fontSize: '16px', color: '#46b6ff'}} />
                        &nbsp; <span style={{textTransform: 'lowercase'}}>i</span>OS APP
                      </div>
                    </a>
                  </div>

                  <div className=" ">
                    <button className="col-12 bg-white" style={{border: '0px solid blue', paddingLeft: '6px'}}>
                      <details className="accordion " closed="true">
                        <input type="checkbox" hidden={true} />
                        <summary className=" accordion-header  btn btn-md " style={{border: '0px solid blue', borderRadius: '0'}}>
                          <div className="panel-header text-primary col-12 col-mx-auto text-left  " style={{border: '0px solid blue', paddingTop: '.4rem'}}>
                            &nbsp;&nbsp;Connect With Us <i className="icon  icon-arrow-right mr-1 float-right" />
                          </div>
                        </summary>
                        <div className="accordion-body bg-white zvideobottom ">
                          <div>
                            <ul className="text-small text-left " style={{listStyle: 'none', paddingLeft: '.2rem'}}>
                              <a
                                href="https://www.facebook.com/disclosures.io"
                                style={{color: '#46b6ff'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                universal="true">
                                <li>
                                  <i className="fab fa-facebook-square fa-lg" style={{color: '#46b6ff'}} /> &nbsp; FACEBOOK
                                </li>
                              </a>
                              <a
                                href="https://www.instagram.com/disclosures.io"
                                style={{color: '#46b6ff'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                universal="true">
                                <li className="pt-2">
                                  <i className="fab fa-twitter-square fa-lg" style={{color: '#46b6ff'}} />
                                  &nbsp; INSTAGRAM
                                </li>
                              </a>
                              <a
                                href="https://www.linkedin.com/company/disclosuresio"
                                style={{color: '#46b6ff'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                universal="true">
                                <li className="pt-2">
                                  <i className="fab fa-linkedin fa-lg" style={{color: '#46b6ff'}} /> &nbsp; LINKEDIN
                                </li>
                              </a>
                              <a href="https://twitter.com/disclosuresio" style={{color: '#46b6ff'}} rel="noopener noreferrer" target="_blank" universal="true">
                                <li className="pt-2">
                                  <i className="fab fa-twitter-square fa-lg" style={{color: '#46b6ff'}} /> &nbsp; TWITTER
                                </li>
                              </a>
                              <a
                                className=""
                                href={'https://active.disclosures.io/'}
                                style={{color: '#46b6ff'}}
                                target="_blank"
                                rel="noopener noreferrer"
                                universal="true">
                                <li className="pt-2">
                                  <i className="fas fa-glasses fa-lg" style={{color: '#46b6ff'}} /> &nbsp; BLOG
                                </li>
                              </a>
                            </ul>
                          </div>
                        </div>
                      </details>
                    </button>
                  </div>
                  <div className="pt-1 ">
                    <a href="https://w.disclosures.io/lunch-and-learn" target="_blank" rel="noopener noreferrer" universal="true" style={{color: '#46b6ff'}}>
                      <div className="btn  btn-md col-12 text-left  panel-header" style={{border: '0px solid blue', color: '#46b6ff'}}>
                        &nbsp; Join the Next Webinar
                        <i className="icon  icon-arrow-right mr-1 float-right" />
                      </div>
                    </a>
                  </div>

                  <div
                    className="col-12 border-top  text-right zvideobackback "
                    style={{
                      position: 'fixed',
                      bottom: '1%',
                      right: '0%',
                      paddingTop: '1rem',
                      paddingBottom: '1rem',
                    }}>
                    <h4 class="pb-2 pt-12 text-center px-8">Getting started is easy, and free!</h4>
                    <p class="pb-0 px-8 text-center">Enter the email that you would like as your main account login. </p>

                    <SignupFormMob size="sm" />

                    <div className="pt-4 pb-4  text-center">
                      Already have an account? <br />
                      <a href={`${appUrl}/auth/login`} style={{color: '#46b6ff'}}>
                        <button className="btn  btn-md col-12 " style={{border: '0 solid blue', textDecoration: 'underline'}}>
                          Log In Here
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                {/* MAIN CONTAINER START*/}
                <div key="body" className="sticky-container ">
                  <div className="sticky-content">
                    <div className="bg-white text-light ">
                      <div className="th-home--navbar-container-index  text-light bg-transparent ">
                        <div className="container grid-xl ">
                          <NavbarIndex onClickMenu={this.onClick} />
                        </div>
                      </div>
                    </div>

                    <div key="body" className="">
                      {/* BODY */}

                      <div className=" th-home--gradient  th-home--mobhid  px-6 pt-6">
                        {/* DESKTOP TOP */}

                        <div align="center" className=" mt-0 pt-20 pb-20 container grid-xl ">
                          <div className="column col-12 ">
                            <div
                              className="th-home--splash-content text-light  th-home--padmob-index th-home--mobiletopindex text-left"
                              style={{paddingBottom: '5em'}}>
                              <h2 className="th-home--mobhid pt-8">A listing tool kit for real estate professionals</h2>

                              <p className="th-home--mobhid  pb-4" style={{fontSize: '1em', textAlign: 'left', maxWidth: '850px'}}>
                                HomeLight Listing Management (HLM) helps top agents make every transaction simple, certain, and satisfying. Manage, market, and
                                sell real estate in one secure location.
                              </p>
                              <WelcomeVideoHome />
                            </div>
                          </div>
                        </div>
                        {/* ANNOUNCEMENT
                        <div className="announcement-container th-home--mobhid" style={{}}>
                          <div class="container grid-xl ">
                            <div class=" bg-transparent announcement-bar">
                              <p className=" text-left " style={{fontSize: '.9em'}}>
                                *Disclosures.io is now "HomeLight Listing Management" (HLM). Read more about our growth and success story —{' '}
                                <a href="/features " style={{color: '#273753', textDecoration: 'underline'}}>
                                  here!
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>   */}
                      </div>

                      {/* MOBILE TOP */}
                      <div className="th-home--gradient container grid-xl th-home--splash-content  th-home--padmob-index th-home--mobiletopindex th-home--deskhid ">
                        <h1 className="th-home--deskhid th-home--mobilefonth1 text-left text-white px-4 ">A listing tool kit for real estate professionals</h1>

                        <div className="th-home--deskhid " style={{display: 'flex', flexDirection: 'column', height: 'inherit'}}>
                          <p className="th-home--mobilefonth4 text-white text-left px-4 pb-4">
                            HomeLight Listing Management (HLM) helps top agents make every transaction simple, certain, and satisfying. Manage, market, and sell
                            real estate in one secure location.
                          </p>
                        </div>

                        <div class="px-4" style={{color: '#000000'}}>
                          <WelcomeVideoHome />
                        </div>
                      </div>
                      {/* ANNOUNCEMENT 
                      <div className="announcement-container th-home--deskhid">
                        <div class="container grid-xl bg-primary" style={{backgroundColor: '#ffec46'}}>
                          <div class=" bg-transparent announcement-bar">
                            <p className=" text-left " style={{fontSize: '.8em', paddingTop: '0px'}}>
                              *Disclosures.io is now "HomeLight Listing Management" (HLM). Read more about our growth and success story —{' '}
                              <a href="/features " style={{color: '#273753', textDecoration: 'underline'}}>
                                here!
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>*/}
                    </div>

                    <div class="sticky-header">
                      <Signupnowbar />
                    </div>
                    <div class="">
                      <Featuresindex />
                    </div>

                    <div class="th-home--padhid">
                      <Usercount />
                    </div>

                    <div class="th-home--deskhid">
                      <Peerreviews />
                    </div>

                    <div class="">
                      <Howandwhy />
                    </div>
                  </div>
                  <Signupnow2 />

                  <Footer />
                </div>
              </OffCanvas>
            </div>
          )
        }}
      />
    )
  }
}
export default IndexPage
